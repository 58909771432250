<template>
<div class="classes">
  <h1 class="title grey--text">班级列表</h1>
  <v-container class="my-5">
    <!-- <v-layout justify-center class="pa3">
      <v-flex xs12 sm10 md8>
        <v-select
          v-model="programIds"
          :items="programs"
          item-text="title"
          item-value="program_id"
          :label="programs.length > 0? '所选项目': '暂无项目，请先创建项目'"
          :disabled="!(programs.length > 0)"
          chips
          multiple
          prepend-icon="ballot"
          menu-props="offsetY"
          @change="updateClasses"
        ></v-select>
      </v-flex>
    </v-layout> -->

    <v-layout justify-center class="mb-2">
      <v-flex class="d-flex justify-center">
        <v-btn
          text
          outlined
          @click="$router.push({
            name: 'create-class',
            query: $route.query
          })">
          <v-icon>add</v-icon>
          创建班级
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- 班组卡片 -->
    <div
      v-for="(singleClass, indexClass) in classesShow" v-bind:key="`class-${indexClass}`"
      :class="`my-5 classes class-status-code-${singleClass.status_code}`"
    >
      <y-class :singleClass=singleClass />
    </div>
  </v-container>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import Vue from 'vue'
import YClass from '../components/YClass.vue'

export default {
  components: {
    YClass
  },
  data () {
    return {
      programs: [],
      programIds: [],
      organizationIds: [],
      tutorIds: [],
      classesShow: []
    }
  },
  watch: {
    async $route (to, from) {
      if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
        console.log('检测到路径参数变化')
        this.resetData()
        // set queryingState
        this.$store.dispatch('setQueryingState', true)
        // detect query
        if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
          if (this.$route.query.organizationId) {
            this.organizationIds = [this.$route.query.organizationId]
          }
          if (this.$route.query.tutorId) {
            this.tutorIds = [this.$route.query.tutorId]
          }
          if (this.$route.query.programId) {
            this.programIds = [this.$route.query.programId]
          }
          console.log('成功获取路径参数！')
        } else { // 如果没有任何中路径参数, 考虑查找当前用户角色
          if (this.$store.state.account.organizations) {
            this.organizationIds = this.$store.state.account.organizations.map(organization => organization.id)
            console.log('使用户机构参数！')
          } else if (this.$store.state.account.tutor) {
            this.tutorIds = [this.$store.state.account.tutor.id]
            console.log('使用户教师参数！')
          }
        }

        // refresh classInfo
        await this.refreshClassInfo()

        // set queryingState
        this.$store.dispatch('setQueryingState', false)
      }
    }
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    // detect query
    if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
      if (this.$route.query.organizationId) {
        this.organizationIds = [this.$route.query.organizationId]
      }
      if (this.$route.query.tutorId) {
        this.tutorIds = [this.$route.query.tutorId]
      }
      if (this.$route.query.programId) {
        this.programIds = [this.$route.query.programId]
      }
      console.log('成功获取路径参数！')
    } else { // 如果没有任何中路径参数, 考虑查找当前用户角色
      if (this.$store.state.account.organizations) {
        this.organizationIds = this.$store.state.account.organizations.map(organization => organization.id)
        console.log('使用户机构参数！')
      } else if (this.$store.state.account.tutor) {
        this.tutorIds = [this.$store.state.account.tutor.id]
        console.log('使用户教师参数！')
      }
    }

    // refresh classInfo
    await this.refreshClassInfo()

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    resetData () {
      this.programs = []
      this.programIds = []
      this.organizationIds = []
      this.tutorIds = []
      this.classesShow = []
    },
    async refreshClassInfo () {
      // prepare Arguments
      let param = {
        programIds: this.programIds.length === 0 ? null : this.programIds,
        organizationIds: this.organizationIds.length === 0 ? null : this.organizationIds,
        tutorIds: this.tutorIds.length === 0 ? null : this.tutorIds,
        attachTutors: true
      }
      // get classes by program Id
      this.classesShow = await this.getClasses(param)
      // add show property
      this.classesShow.forEach(element => {
        Vue.set(element, 'show', false) // Use vue.set to dynamically add properties to object and make it reactive
        Vue.set(element, 'students', [])
        Vue.set(element, 'studentEnrollmentDialog', false)
        Vue.set(element, 'deleteClassDialog', false)
        Vue.set(element, 'editClassStatusDialog', false)
        Vue.set(element, 'venueAssignmentRemovalDialog', false)
      })
    },
    async getClasses ({ programIds, tutorIds, organizationIds, attachTutors }) {
      const res = await ProgramService.getClasses({ programIds, organizationIds, instructorTutorIds: tutorIds, attachTutors })
      console.log('Retreving classes successfully: ', res.data)
      return res.data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.classes.class-status-code-1 {
  border-left: 4px solid #3cd1c3;
}
.classes.class-status-code-0 {
  border-left: 4px solid tomato;
}
.classes.class-status-code-2 {
  border-left: 4px solid orange;
}
</style>
