<template>
<v-card>
  <v-layout column >
    <!-- 基本信息，标识位置卡片封面 -->
    <div class="px-5 pt-5">
      <v-flex>
        <div class="subtitle-1 font-weight-bold">{{singleClass.program}}</div>
        <div class="title font-weight-bold">{{ singleClass.class }}</div>
      </v-flex>
      <v-flex>
        <div class="caption grey--text">教师</div>
        <div class="subtitle-2">
          <span
            v-for="instructor in singleClass.tutors"
            v-bind:key="instructor.tutorId"
          >
            {{ instructor.tutor }} <span v-if="$store.state.roleCodes.some((el) => [RoleCodes.ADMIN, RoleCodes.MANAGER].includes(el))">({{instructor.compensationPermyriad / 100}}%)</span>
          </span>
        </div>
      </v-flex>
      <v-flex>
        <div class="caption grey--text">描述</div>
        <div class="subtitle-2">{{ singleClass.description }}</div>
      </v-flex>
      <v-flex>
        <div class="caption grey--text">场地</div>
        <div v-if="singleClass.venue" class="subtitle-2">{{ singleClass.venue }} <span v-if="$store.state.roleCodes.some((el) => [RoleCodes.ADMIN, RoleCodes.MANAGER].includes(el))">({{singleClass.venue_compensation_percentage}}%)</span></div>
      </v-flex>
      <v-flex>
        <div class="caption grey--text">状态</div>
        <div class="subtitle-2">{{ singleClass.status }}</div>
      </v-flex>
    </div>
    <v-divider class="mt-3"></v-divider>
    <v-flex class="d-flex justify-center" @click="getClassDetails()">
      <v-btn text block small tile>
        <span class="caption grey--text">{{singleClass.show ? '收起' : '展开'}}班级详情</span>
        <v-icon>{{ singleClass.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
      </v-btn>
    </v-flex>

    <!-- 卡片详情 -->
    <v-flex>
      <v-slide-y-transition>
        <v-card-text v-show="singleClass.show" class="grey--text">

          <!-- 场地承接信息 -->
          <v-divider></v-divider>
          <div class="mb-3">
            <v-subheader> 场地承接
              <v-icon @click="venueAssignmentDialog">
                edit
              </v-icon>
              <v-dialog v-model="singleClass.venueAssignmentDialog" max-width="600px" right>
                <v-card>
                  <v-card-title>
                    <span class="headline">选定承接场地</span>
                  </v-card-title>

                  <v-card-text>
                    <v-icon>person_outline</v-icon> 承接>
                    <span>
                      {{ selectedVenue.host_nickname }}
                    </span> |
                    <v-icon>store</v-icon> 定位>{{ selectedVenue.street }}
                    <v-select
                      v-model="selectedVenue"
                      :items="venues"
                      item-text="venue_name"
                      label="选择场地"
                      single-line
                      return-object
                    ></v-select>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="singleClass.venueAssignmentDialog = false">关闭</v-btn>
                    <v-btn color="warning" @click="venueAssignment(singleClass.class_id, selectedVenue.venue_id)">确定</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-subheader>
            <v-icon>person_outline</v-icon> 承接>
            <span
              :style="{ cursor: 'pointer'}"
              @click="$router.push({
                name: 'host-income',
                params:{
                  hostId: singleClass.host_id,
                }
              })"
            >
              {{ singleClass.host }}
            </span> |
            <v-icon>store</v-icon> 场地>{{ singleClass.venue_description }}
          </div>

          <!-- 班级学员信息 -->
          <v-divider></v-divider>
          <div class="mb-3">
            <v-subheader>
              班级学员
              <v-dialog v-model="singleClass.studentEnrollmentDialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-btn text icon color="grey" v-on="on" @click="studentClassEnrollInit()">
                    <v-icon>
                      group_add
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline">增加学员</span>
                  </v-card-title>

                  <v-card-text>
                    <p>请选择需要增加的学员</p>
                    <div v-for="(unenrolledStudent, indexUnenrolledStudent) in unenrolledStudents" v-bind:key="`unenrolledStudent-${indexUnenrolledStudent}`">
                      <v-checkbox :v-if="`${unenrolledStudent.program_id == singleClass.program_id}`"
                        v-model="unenrolledStudentIdsSelected"
                        :label="`${unenrolledStudent.student_name} (课时：${unenrolledStudent.lesson_quantity})`"
                        :value="`${unenrolledStudent.student_id}`">
                      </v-checkbox>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="singleClass.studentEnrollmentDialog = false">取消</v-btn>
                    <v-btn color="success" @click="studentClassEnroll(unenrolledStudentIdsSelected, singleClass.class_id)">确定</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-subheader>
            <span v-for="(student, indexStudent) in singleClass.students" v-bind:key="`student-${indexStudent}`">
              <v-chip
                :color="`${student.sex == 'M' ? 'blue lighten-3': 'pink lighten-2'}`"
                text-color="white"
                class="ma-1"
              >
                <v-avatar
                  :class="`${student.lesson_quantity - student.unfinalizedLessonUsageQuantity > 10 ? 'green' : 'red'}`"
                  left
                  @click="$router.push({
                    name: 'student-details',
                    params:{
                      studentId: student.student_id,
                      programId: singleClass.program_id
                    }
                  })"
                >
                  {{ student.lesson_quantity }}
                </v-avatar>
                {{student.unfinalizedLessonUsageQuantity > 0 ? `(待结:${student.unfinalizedLessonUsageQuantity}) ` : ''}}{{ student.student_name }}{{ student.studentRealName? `/${student.studentRealName}`: ''}}{{ student.studentEnglishName? `/${student.studentEnglishName}`: ''}}
                <v-dialog v-model="student.studentDisenrollmentDialog" max-width="600px" right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      clear
                    </v-icon>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="headline">移除学员</span>
                    </v-card-title>

                    <v-card-text>
                      <p> 确认将学员 {{ student.student_name }} 移除本班({{ singleClass.class }})? </p>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="student.studentDisenrollmentDialog = false">关闭</v-btn>
                      <v-btn color="warning" @click="studentClassDisenrollment(student.student_id, student.class_id, indexStudent)">删除</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-chip>
            </span>
          </div>

          <!-- 其它操作 -->
          <v-divider></v-divider>
          <div>
            <v-subheader> 其它操作 </v-subheader>

            <v-dialog v-model="singleClass.editClassStatusDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" color="info"
                  @click="editClassStatusInit()"
                >
                  修改本班状态
                  <v-icon>
                    edit_attributes
                  </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">修改班级状态</span>
                </v-card-title>

                <v-card-text>
                  <v-radio-group v-model="singleClass.status_code" :mandatory="false">
                    <div v-for="(classStatusItem, indexClassStatusItem) in classStatusItems" v-bind:key="`classStatusItem-${indexClassStatusItem}`">
                      <v-radio :label="classStatusItem.status" :value="classStatusItem.code"></v-radio>
                    </div>
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="singleClass.editClassStatusDialog = false">取消</v-btn>
                  <v-btn color="warning" @click="updateClassStatus(singleClass.class_id, singleClass.status_code)">确定</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="singleClass.deleteClassDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" color="error"
                  @click="singleClass.deleteClassDialog = true"
                  :disabled="singleClass.students.length > 0"
                >
                  删除
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">删除班级</span>
                </v-card-title>

                <v-card-text>
                  <p>确定删除班级：{{ singleClass.class }}</p>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="singleClass.deleteClassDialog = false">取消</v-btn>
                  <v-btn color="warning" @click="deleteClass(singleClass.class_id)">确定</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="classCalendarDialog" max-width="900px">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="classCalendarDialog=true"
                  text
                  v-on="on"
                  color="info"
                >
                  课程表
                  <v-icon>
                    calendar_month
                  </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">{{singleClass.class}} 课程表</span>
                </v-card-title>

                <v-card-text>
                  <y-lesson-calendar :classIds="[singleClass.class_id]"/>
                </v-card-text>
              </v-card>
            </v-dialog>

          </div>
        </v-card-text>
      </v-slide-y-transition>
    </v-flex>
  </v-layout>
  <v-divider></v-divider>
</v-card>
</template>

<script>
import Vue from 'vue'
import ProgramService from '../services/ProgramService'
import util from '../util'
import YLessonCalendar from '../components/YLessonCalendar.vue'
import { RoleCodes } from '../enums'
// import { VenueStatusLookup } from '../enums'
export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    singleClass: {
      type: Object,
      default: null
    }
  },
  components: {
    YLessonCalendar
  },
  data () {
    return {
      unenrolledStudents: [],
      unenrolledStudentIdsSelected: [],
      classStatusItems: [],
      classCalendarDialog: false,
      venues: [],
      selectedVenue: {},
      RoleCodes: RoleCodes
    }
  },
  computed: {
  },
  async mounted () {
  },
  filters: {
  },
  methods: {
    async getClassDetails () { // done
      const classId = this.singleClass.class_id
      console.log('\nThe "detail" button is clicked! classId: ', classId)
      console.log('singleClass: ', this.singleClass)
      this.singleClass.show = !this.singleClass.show

      if (this.singleClass.students.length === 0) {
        let students = await this.getStudentsByClassId(classId)
        students.forEach(student => {
          Vue.set(student, 'studentDisenrollmentDialog', false)
        })
        this.singleClass.students = students
        console.log('singleClass: ', this.singleClass)
      }
    },
    async getStudentsByClassId (classId) { // done
      console.log('\ngetStudentsByClassId start, classId: ', classId)
      console.log('query send, waiting for server response ... ')
      const res = await ProgramService.getStudents({ classIds: [classId] })
      let classStudents = res.data
      console.log('Retrieved students: ', classStudents)
      return classStudents
    },
    async studentClassDisenrollment (studentId, classId, indexStudent) { // done
      console.log('\n#studentClassDisenrollment start ... \nConfirming to disenroll student (studentId: ', studentId, ') from class (classId: ', classId, ')')
      await ProgramService.studentClassDisenrollment(studentId, classId)
      this.singleClass.students[indexStudent].studentDisenrollmentDialog = false
      let students = await this.getStudentsByClassId(classId)
      students.forEach(student => {
        Vue.set(student, 'studentDisenrollmentDialog', false)
      })
      this.singleClass.students = students
    },
    async studentClassEnrollInit () { // done
      console.log('\nstudentClassEnrollInit start ... \nRetrieve unenrolled students information under current program ... ')
      // Retrieve unenrolled students information under current program
      const programId = this.singleClass.program_id
      const res = await ProgramService.getStudents({ programIds: [programId] })
      const students = res.data
      if (students.length > 0) {
        const enrollmentStatusCodes = [0, 2, 3, null]
        console.log(`retrieve all students under program: ${students}, filter using enrollmentSatusCodes: ${enrollmentStatusCodes}`)
        this.unenrolledStudents = students.filter((student) => {
          let show = false
          show = util.inArray(student.enrollment_status_code, enrollmentStatusCodes) && student.lesson_quantity > 0
          return show
        })
        console.log('unenrolledStudents (programId: ', this.programIds, '): ', this.unenrolledStudents)
        this.singleClass.studentEnrollmentDialog = true
      }
    },
    async studentClassEnroll (studentIds, classId) { // done TO-DO: unenrolledStudentIdsSelected sometimes might change into var not [], this will make enrollment failure (but if user try multiple times it will work)
      console.log('\nstudentClassEnroll initiated ...')
      console.log('\nstudentId: ', studentIds, '\nclassId: ', classId)
      console.log('\nStart to enroll ...')
      const response = await ProgramService.studentClassEnroll(studentIds, classId)
      const studentClassEnrollmenteIds = response.data
      console.log('\nEnrollment successful! studentClassEnrollmenteIds: ', studentClassEnrollmenteIds)
      this.singleClass.studentEnrollmentDialog = false
      this.unenrolledStudentIdsSelected = []
      console.log('\nRefreshing the enrolled student')
      let students = await this.getStudentsByClassId(classId)
      students.forEach(student => {
        Vue.set(student, 'studentDisenrollmentDialog', false)
      })
      this.singleClass.students = students
    },
    async editClassStatusInit () { // done
      // get class status
      if (this.classStatusItems.length < 3) { // classStatusItems hasn't been retrieved
        console.log('Retrieving class status items ... ')
        const res = await ProgramService.getClassStatusItems()
        this.classStatusItems = res.data
        console.log('Success! classStatusItems: ', this.classStatusItems)
      }
      // open the dialog
      this.singleClass.editClassStatusDialog = true
    },
    async updateClassStatus (classId, statusCode) { // done
      await ProgramService.updateClassStatus(classId, statusCode)
      // refresh classInfo
      // this.refreshClassInfo()
      // close the dialog
      this.singleClass.editClassStatusDialog = false
    },
    async deleteClass (classId) {
      await ProgramService.deleteClass(classId)
      this.singleClass.deleteClassDialog = false
      // refresh classInfo
      this.$router.go()
    },
    async getVenueByClassId (classId) {
      console.log('\ngetVenueByClassId starts ... ')
      const res = await ProgramService.getVenueByClassId(classId)
      return res.data.venueInfo
    },

    async venueAssignmentRemoval (classId, venueId) {
      console.log('\n venueAssignmentRemovalDialog starts ... ')
      await ProgramService.venueAssignmentRemoval(classId, venueId)
      console.log('\n Successful! Refreshing class infomation ... ')
      // refresh classInfo
      this.refreshClassInfo()
      // close the dialog
      this.singleClass.venueAssignmentRemovalDialog = false
    },
    async venueAssignmentDialog () {
      Vue.set(this.singleClass, 'venueAssignmentDialog', true)
      if (this.venues.length === 0) {
        const res = await ProgramService.getVenues({})
        this.venues = res.data
      }
      if (this.singleClass.venue_id) {
        this.selectedVenue = this.venues.find(venue => venue.venue_id === this.singleClass.venue_id)
      }
    },
    async venueAssignment (classId, venueId) {
      console.log('\n venueAssignmentDialog starts ... ')
      await ProgramService.venueAssignment({ classId, venueId })
      console.log('\n Successful! Refreshing class information ... ')
      // close the dialog
      this.singleClass.venueAssignmentDialog = false
      // refresh info
      this.$router.go()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
